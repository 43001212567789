import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [5];

export const dictionary = {
		"/(main)/(two-column)": [10,[2,4]],
		"/(main)/(two-column)/blog/kategorie/[slug]": [12,[2,4]],
		"/(main)/(two-column)/blog/tagi/[slug]": [13,[2,4]],
		"/(main)/(two-column)/blog/[slug]": [11,[2,4]],
		"/(main)/(pages)/kontakt": [6,[2,3]],
		"/(main)/(pages)/newsletter/potwierdzenie": [~7,[2,3]],
		"/(main)/(pages)/o-mnie": [8,[2,3]],
		"/(main)/(pages)/polityka-prywatnosci": [9,[2,3]],
		"/(main)/(two-column)/preview/blog/post/[slug]": [~14,[2,4,5]],
		"/(maintenance)/przerwa-techniczna": [15]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';